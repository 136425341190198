import React, { useContext } from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { FaCartArrowDown, FaShoppingCart, FaGamepad, FaAndroid } from "react-icons/fa";

import {useNavigate} from 'react-router-dom';

export const GoToCart = () => {

  const navigate = useNavigate()
  
  return (
    <div>

<Button type="button" className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" onClick={()=>navigate("/cart")}>         
              <FaShoppingCart />&nbsp;&nbsp;&nbsp;Перейти в корзину / оформить заказ
            </Button>
      
 
      </div>
    
  
  )
}

export default GoToCart

