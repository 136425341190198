import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet-async';
import BackButton from "../../components/BackButton";
import BuyButton from "../../components/BuyButton";
import GoToCartButton from "../../components/GoToCartButton";


export class Zvezdy extends Component {

  
  render()
  
  {
    return (
      <div>
    <Helmet>
    <title>Игра "Зведы на ладошке" - купить игры по экологии</title>
    <meta name="description" content="Экоигра 'Звезды на ладошке' - это набор из десяти деревянных звёздных карточек с самыми распространёнными созвездиями." />
    <link rel="canonical" href="https://naturegames.ru/zvezdy-na-ladoshke" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    {/* <meta name="title" content="Экоигра 'Звезды на ладошке'" /> */} 
    </Helmet>
      
      <div className="block">
   
        
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог игр</a></li>
          <li className="breadcrumb-item">Настольные игры</li>
          <li className="breadcrumb-item active" aria-current="page">Звезды на ладошке</li>
        </ol>
      </nav>
      <div itemscope itemtype='http://schema.org/Product'>

      <h1 itemprop="name">Звезды на ладошке</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button> */}
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/znl/znl-1.jpg" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
    <div className="carousel-item">
      <img src="img/znl/znl-2.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/znl/znl-3.jpg" className="d-block w-100" alt="..."/>
    </div>
    {/* <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-4.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-5.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-6.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-7.jpg" className="d-block w-100" alt="..."/>
    </div> */}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6">    
  <p><b>Настольная экологическая игра - карточки для детей</b></p>
    
    <p itemprop="description">Набор из десяти деревянных звёздных карточек с самыми распространёнными созвездиями. Буклет с описанием игр и занятий, немного информации о каждом созвездии, списки книг и приложений для смартфонов, чтобы узнать больше о созвездиях и космосе в целом.</p>

    {/* <button className="btn btn-success btn-send pt-2 btn-block" onClick={()=>navigate("/customer-info")}>Продолжить покупки</button> */}
    <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">1150</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>

  <br></br>
  <BuyButton ItemId={5} />
  <GoToCartButton/>
  <BackButton/>
  </div>


     

  </div>
</div>
      </div>
      </div>
    )
  }
}

export default Zvezdy