import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../components/BackButton";
import BuyButton from "../../components/BuyButton";
import GoToCartButton from "../../components/GoToCartButton";
import { Helmet } from 'react-helmet-async';

export class EmptyBucket extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <title>Настольная экологическая игра - карточки-магниты 'Пустое ведро'</title>
    <meta name="description" content="Карточки-магниты с яркими авторскими рисунками станут помощниками для изучения экологических тем, таких как осознанное потребление, переработка отходов и многоразовые аналоги. " />
    <link rel="canonical" href="https://naturegames.ru/empty-bucket" />  
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    {/* <meta name="title" content="Настольная экологическая игра - карточки-магниты 'Пустое ведро'" />     */}
    </Helmet>
      <div className="block">
     
        {/* <img className='imgApp' src="img/forest_cards_phone_2.png" alt="" width="30%"/> */}
           <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог игр</a></li>
          <li className="breadcrumb-item">Настольные игры</li>
          <li className="breadcrumb-item active" aria-current="page">Пустое ведро</li>
        </ol>
      </nav>

      <div itemscope itemtype='http://schema.org/Product'>
      <h1 itemprop="name">Пустое ведро</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/eb/eb-1.jpg" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
    <div className="carousel-item">
      <img src="img/eb/eb-2.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/eb/eb-3.jpg" className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6">    
  <p><b>Настольная экологическая игра - карточки-магниты</b></p>
  <p itemprop="description">Карточки-магниты с яркими авторскими рисунками станут помощниками для изучения экологических тем как осознанное потребление, переработка отходов и многоразовые аналоги. Набор включает в себя 25 карточек отходов, 7 карточек категорий, 1 карточка ведра. Игра развивает навыки мышления и решения проблем.</p>
  
  <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">1000</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>
  <br></br>
  <BuyButton ItemId={6} />
  <GoToCartButton/>
  <BackButton/>
  </div>

  
  </div>
</div>
        
      </div>
      </div>
    )
  }
}

export default EmptyBucket