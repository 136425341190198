import React, { Component } from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import Item from "../../pages/cart/Item";
import Items from "../../pages/cart/Items";
import { PRODUCTS } from '../../itemsArray';
import { Helmet } from 'react-helmet-async';

export class GamesOnline extends Component {
    render() {
    return (
      
 
      <div className='content'>

  <Helmet>
    <title>Экологические игры - играть онлайн</title>
     <meta name="description" content="Здесь вы сожете сыграть онлайн в некоторые экологические игры - сортируй, мемо - птицы и сортировка мусора" />
    <link rel="canonical" href="https://naturegames.ru/games-online" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />
</Helmet>


      <div><h1>Экологические Онлайн-Игры</h1></div>
      <div>
В этом разделе представлены <strong>онлайн игры</strong>, где каждый может играть бесплатно. Наши игровые проекты созданы для того, чтобы помочь вам узнать больше об экологии и охране окружающей среды, а также развить ваше экологическое мышление. Выбирайте игру по вкусу и присоединяйтесь к нам в увлекательном путешествии по миру экологии!
</div>
      <br></br>

        <Row xs={1} md={4} className="g-4">

        {PRODUCTS.map((product)=>(
          product.category.includes('games-online') && <Item onShowItem={this.props.onShowItem} key={product.id} data={product}/>
           
        ))}
        </Row>

      </div>

    )
  }
}

export default GamesOnline