import React, { useContext } from 'react';
import {PRODUCTS} from "../../itemsArray";
import {CartItem} from './cart-item';
import {ShopContext} from '../../context/shop-context';
import { FaLongArrowAltLeft, FaCartArrowDown} from "react-icons/fa";
import "./cart.css";
import { Row, Card, Col, Button } from 'react-bootstrap';

import {useNavigate} from 'react-router-dom';

export const Cart = () => {
  
  const {cartItems, getTotalCartAmount, checkout} = useContext(ShopContext);
  const totalAmount = getTotalCartAmount()
  const navigate = useNavigate()
  
  return (
    <div className="cart">
      <div>
        <h1>Ваши  товары в корзине:</h1>
      </div>
      <div className="cartItems">
        {PRODUCTS.map((product)=>{
          if (cartItems[product.id] !== 0) {
            return <CartItem data={product}/>;
          }
        })}
      </div>
      {totalAmount > 0 ? (
      <div className="checkout">     
        <p>Итого: {totalAmount} руб</p>
        <Button type="button" className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" onClick={()=>navigate("/")}><FaLongArrowAltLeft />&nbsp;&nbsp;&nbsp;Продолжить покупки</Button>
        <Button type="button" className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" onClick={()=>navigate("/customer-info")}> <FaCartArrowDown />&nbsp;&nbsp;&nbsp;Оформить заказ</Button>
      </div>
        ) : (
          <h3>Корзина пуста</h3>
        )}


    </div>
  )
}
