import React, { Component } from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import ItemCatalogue from "../cart/ItemCatalogue";
import { PRODUCTS } from '../../itemsArray';
import { Helmet } from 'react-helmet-async';

export class Catalogue extends Component {
    render() {
    return (
       <div className='content'>
  <Helmet>
    <title>Каталог икологических игр и пособий - купить в магазине</title>
    <meta name="description" content="Выбрать и купить игры по экологии в нашем каталоге - Сортируй, Возьми в лес, Пустое ведро, Экослед товара и другие..." /> 
    <link rel="canonical" href="https://naturegames.ru/catalogue" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />  
</Helmet>

      <div><h1>Каталог игр и пособий по экологии</h1></div>
      <div><strong>Байкальский интерактивный экологический центр</strong> предлагает разнообразные игры по экологии и пособия, в том числе собственные разработки такие как <strong>«Возьми в лес»</strong>, <strong>«Сортируй»</strong>, <strong>«Жизнь без отходов</strong>. Наши эко-игры подходят детям различного возраста для познания окружающего мира, развивают наблюдательность, творческие способности и формируют экологические привычки и ответственное отношение к природе. Они помогут как родителям, так и преподавателям в занятиях по экологии и биологии. Для некоторых игр есть онлайн версии, в которые можно сыграть абсолютно бесплатно.</div>
      <br></br>

      {/* <Card> */}


       {PRODUCTS.map((product)=>(
          product.category.includes('games-offline') && <ItemCatalogue onShowItem={this.props.onShowItem} key={product.id} data={product}/>           
        ))}

        {/* </Card> */}
    
        <div><h1>Каталог Книг</h1></div>
      <br></br>

      {/* <Card> */}


       {PRODUCTS.map((product)=>(
          product.category.includes('books') && <ItemCatalogue onShowItem={this.props.onShowItem} key={product.id} data={product}/>   
        ))}


        
<div><h1>Каталог Онлайн-Игр</h1></div>
      <br></br>

      {/* <Card> */}


       {PRODUCTS.map((product)=>(
          product.category.includes('games-online') && <ItemCatalogue onShowItem={this.props.onShowItem} key={product.id} data={product}/>   
        ))}


      </div>

    )
  }
}

export default Catalogue