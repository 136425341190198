import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet-async';

export class Payment extends Component {
  render() {
    return (
      <div className="block">
        <Helmet>
          <title>Экологические игры - оплата товара</title>
          <link rel="canonical" href="https://naturegames.ru/payment" /> 
          <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />
          <meta name="description" content="Оплата товара в магазине экологических игр. Как полатить товар." />
       {/* <meta name="title" content="Экологические игры - оплата товара" /> */}
      </Helmet>
      
      <div>
        <h1>Оплата</h1>
        <p>Оплата осуществляется после уточнения заказа и стоимости доставки на банковский счет по реквизитам организации или на карту.</p>
        <p>Обратите внимание, что стоимость заказа включат в себя стоимость товара и стомость доставки, которая рассчитвыается индивидуально в зависимости от веса посылки и вашего региона проживания.</p>
        <h1>Доставка</h1>
        <p>Отправим в любую точку России.</p>
        <p>Самовывоз из Иркутска - бесплатно.</p>
        <p>Доставка по России - почтой после предоплаты. К цене игры добавится стоимость почтовых услуг 200-400 рублей.</p>
</div>
      </div>
    )
  }
}

export default Payment