import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../components/BackButton";
import BuyButton from "../../components/BuyButton";
import GoToCartButton from "../../components/GoToCartButton";
import { Helmet } from 'react-helmet-async';

export class BaikalAzbuka extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <title>Книга для детей Байкальская азбука</title>
    <meta name="description" content="Хотите познакомить детей с растительным и животным миром Байкала? Рассказать им что-то новое о географических объектах озера? Короткие истории Байкальской азбуки на каждую букву алфавита помогут вам с этим! " />
    <link rel="canonical" href="https://naturegames.ru/baikal-azbuka" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    {/* <meta name="title" content="Книга для детей Байкальская азбука'" /> */}    
    </Helmet>
      <div className="block">
       
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог товаров</a></li>
          <li className="breadcrumb-item">Книги и пособия</li>
          <li className="breadcrumb-item active" aria-current="page">Байкальская азбука</li>
        </ol>
      </nav>

      <div itemscope itemtype='http://schema.org/Product'>
        <h1 itemprop="name">Байкальская азбука</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/baikal-azbuka/ba-1.jpg" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
    <div className="carousel-item">
      <img src="img/baikal-azbuka/ba-2.jpg" className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6">    
 <p><b>Книга для детей</b></p>
 <p itemprop="description">Хотите познакомить детей с растительным и животным миром Байкала? Рассказать им что-то новое о географических объектах озера? Короткие истории "Байкальской азбуки" на каждую букву алфавита помогут вам с этим! Небольшие тексты написаны просто и понятно, начинающие читатели с лёгкостью справятся с ними. А ребята постарше смогут использовать азбуку в учёбе, чтобы подготовить небольшой доклад или сообщение для уроков по окружающему миру.</p>
 <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">350</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>

  <br></br>
  <BuyButton ItemId={9} />
  <GoToCartButton/>
  <BackButton/>
 
<br></br>
 <p><b>Познокомьтесь с другими книгами этой категории:</b></p> 
 <p><a href="https://naturegames.ru/baikal-putanica" className='greenlink'>Книга-раскраска "Байкальская путаница"</a></p>
 
  </div>

 
  </div>
</div>
      </div>
      </div>
    )
  }
}

export default BaikalAzbuka