import React, { Component, useContext } from 'react'
import { Warning } from "../../components/Warning";
import { PRODUCTS } from '../../itemsArray';
import Categories from "../../components/Categories";
import ShowFullItem from "../../components/ShowFullItem";
import Items from "../../pages/cart/Items";
import Cart from "../../pages/cart/cart";
import {ShopContext} from '../../context/shop-context';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const SomeButton = () => {
  const navigation = useNavigate();
  const {sum} = useContext(ShopContext);
  return ( 
    <button className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" onClick={()=>navigation("/cart")}>В корзину - товаров: {sum}</button>
  )
}
 

export class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      currentItems: [],
      items: PRODUCTS,
      showFullItem: false,
      fullItem: {},
    }
    this.state.currentItems = this.state.items
    this.chooseCategory = this.chooseCategory.bind(this)
    this.onShowItem = this.onShowItem.bind(this)
  }
   

  render() {
    return ( 
      <div className="wrapper">
     <Helmet>
     <title>Экологические игры - купить или играть онлайн</title>
    <meta name="description" content="У нас можно купить игры по экологии - Возьми в лес, Сортируй, Экослед товара и дроугие игры и пособия для экопросвещения." />  
    <link rel="canonical" href="https://naturegames.ru" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, эко игры" />
    </Helmet>
        <div className='block'><h1 className='greenlink'>Экологические игры и пособия</h1></div>
      
          <div className="header3"><h2>На нашем сайте вы можете купить экологические игры или <br></br>играть онлайн абсолютно бесплатно</h2></div>
          {/* <Warning /> */}
          <Categories chooseCategory={this.chooseCategory} />
          <Items onShowItem={this.onShowItem} items={this.state.currentItems} />
          {/* {this.state.showFullItem && <ShowFullItem onShowItem={this.onShowItem} item={this.state.fullItem} />} */}
          {/* <div>{getItemsCount}</div> */}
          {/* <button className="btn btn-success btn-send pt-2 btn-block" onClick={()=>useNavigate("/cart")}>В корзину</button> */}
          <div className="cartButton"><SomeButton /></div>
          {/* <ShowAmount/> */}
        
        
        <div className="block">

          <br></br>
          <br></br>
          
<h2>Наши эко-игры помогают:</h2>
<ul>
<li>познакомить детей с проблемами окружающей среды и научить бережному отношению к природе;</li>
<li>научить детей понимать взаимосвязи между различными элементами природы;</li>
<li>расширить знания детей о животных, растениях и других живых организмах;</li>
<li>формировать у детей экологическую культуру, ответственность за состояние окружающей среды и экологические привычки;</li>
<li>развивать творческие способности, наблюдательность, логику, внимание и память.</li>
</ul>


</div>
        </div>
    )

  }

  onShowItem(item) {
    this.setState({ fullItem: item })
    this.setState({ showFullItem: !this.state.showFullItem })
  }

  chooseCategory(category) {
    if (category === 'all') {
      this.setState({ currentItems: this.state.items })
      return
    }

    this.setState({
      currentItems: this.state.items.filter(el => el.category.includes(category))
    })
  }

}

export default Home