import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../components/BackButton";
import BuyButton from "../../components/BuyButton";
import GoToCartButton from "../../components/GoToCartButton";
import { Helmet } from 'react-helmet-async';

export class Ecosled extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <title>Настольная экологическая игра 'Экослед товара'</title>
    <meta name="description" content="В комплект входит около 600 карточек, брошюра, СD диск c дополнительными материалами. На примере шоколадного яйца идет изучение жизненного цикла товара от добычи сырья до момента превращения его компонентов в отходы." />
    <link rel="canonical" href="https://naturegames.ru/ecosled" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />   
    {/* <meta name="title" content="Настольная экологическая игра 'Экослед товара'" /> */}
    </Helmet>
      <div className="block">
      
       
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог игр</a></li>
          <li className="breadcrumb-item">Настольные игры</li>
          <li className="breadcrumb-item active" aria-current="page">Экослед товара</li>
        </ol>
      </nav>
      <div itemscope itemtype='http://schema.org/Product'>

      <h1 itemprop="name">Экослед товара</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button> */}
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/ecosled/ecosled.png" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
     <div className="carousel-item">
      <img src="img/ecosled/ecosled-2.png" className="d-block w-100" alt="..."/>
    </div>
    {/*<div className="carousel-item">
      <img src="img/forest-cards-summer/fc-3.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-4.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-5.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-6.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-7.jpg" className="d-block w-100" alt="..."/>
    </div> */}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6">    
  <p><b>Настольная экологическая игра</b></p>
  <p itemprop="description">В комплект входит около 600 карточек, брошюра, СD диск c дополнительными материалами. На примере шоколадного яйца идет изучение жизненного цикла товара от добычи сырья до момента превращения его компонентов в отходы.</p>

<p>Использование пособия в учебном и воспитательном процессе позволит повысить мотивацию к изменению поведения на экологически дружественное, поможет развить интерес к изучению взаимоотношения человека и природы, сформирует дополнительные знания по экологии, географии, химии, естествознанию, поможет развитию межпредметных знаний. Работа в группе способствует формированию умений работать в команде, организовывать сотрудничество, рассуждать, обобщать, классифицировать. И, как подсказали педагоги, игровое пособие дает представление о множестве профессий, о которых можно говорить, раскладывая производственные цепочки шоколадного яйца.</p>

<p>Игровое пособие «Экослед товара» входит в арсенал интерактивных методов преподавания, которые становятся приоритетными в современном образовании. По отзывам пользователей, пособие оказывает сильное влияние на экологическое сознание и позволяет практически формировать экологическое мышление. Оно может быть использовано во внеурочной, учебно-исследовательской, проектной, игровой, кружковой и досуговой деятельности, в летних и зимних лагерях.</p>

<p>В июне 2015 года пособие "Экослед товара" и его автор Л.Г.Кошкарёва получили Диплом Национальной Экологической Премии в номинации "Образование для устойчивого развития".</p>

<div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">700</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>

  <br></br>
  <BuyButton ItemId={8} />
  <GoToCartButton/>
  <BackButton/>
  </div>

 
  </div>
</div>
      </div>
      </div>
    )
  }
}

export default Ecosled