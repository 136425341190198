import React, { useContext } from 'react'
import { FaShoppingCart, FaGamepad, FaAndroid } from "react-icons/fa";
import { Row, Card, Col, Button } from 'react-bootstrap';
import {ShopContext} from "../../context/shop-context"
import {useNavigate} from 'react-router-dom';

export const Item = (props) => {
  const {id, title, desc, img, price, category, link, gameLink, appLink} = props.data;
  const{addToCart, cartItems} = useContext(ShopContext);
  const cartItemAmount = cartItems[id]
  const navigate = useNavigate()

    return (
        <Col>
        <Card>
        <div itemscope itemtype='http://schema.org/Product'>
          <Card.Img className="itemImg" variant="top" src={"./img/" + img} onClick={()=>navigate("/"+link)} itemprop="image"/>
          <Card.Body>
           <div itemprop="name"><strong><Card.Title onClick={()=>navigate("/"+link)}>{title}</Card.Title></strong></div>
            <Card.Text>
              <span itemprop="description">{desc}</span>
            </Card.Text>
            <Card.Text>
              <span><strong>Цена: </strong></span><span itemprop="price">{price}</span><span> руб.</span>
              <meta itemprop="priceCurrency" content='RUB'/>
            </Card.Text>
                 
             { (category.includes('games-offline') || category.includes('books') || category.includes('souveneers')) && <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" 
            //  onClick={() => this.props.onAdd(this.props.item)}
            onClick={() => addToCart(id)}>         
              <FaShoppingCart />&nbsp;&nbsp;&nbsp;Купить {cartItemAmount>0 && <> ({cartItemAmount})</>}
            </Button>}
             

            { (category.includes('games-online')) && <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" onClick={()=>navigate("/"+gameLink)}>          
              <FaGamepad />&nbsp;&nbsp;&nbsp;Играть
            </Button>}

            { (category.includes('games-app')) && <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" onClick={()=>navigate("/"+appLink)}>          
              <FaAndroid />&nbsp;&nbsp;&nbsp;Приложение
            </Button>}

          </Card.Body>
          </div>
        </Card>

      </Col>
    );
  
};

export default Item