import React, { useContext } from 'react'
import { FaShoppingCart, FaCartArrowDown, FaGamepad, FaAndroid } from "react-icons/fa";
import { Row, Card, Col, Button } from 'react-bootstrap';
import {ShopContext} from "../context/shop-context"
import {useNavigate} from 'react-router-dom';

export const BuyButton = (id) => {
  const {ItemId} = id
    // const {id, title, desc, img, price, category, link, gameLink, appLink} = props.data;
  const{addToCart, cartItems} = useContext(ShopContext);
  // const cartItemAmount = cartItems[id]

  console.log()

    return (

      <div>


                 
<Button type="button" className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" 
            //  onClick={() => this.props.onAdd(this.props.item)}
            onClick={() => addToCart(ItemId)}>         
              <FaCartArrowDown />&nbsp;&nbsp;&nbsp;Добавить в корзину
            </Button>
             
            </div>
    );
  
};

export default BuyButton

