import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { FaWhatsapp, FaVk, FaEnvelope, FaGlobe } from "react-icons/fa";
import { Helmet } from 'react-helmet';

export class Page404 extends Component {
  render() {
    return (
      <div className="block">
        <h2>404 - Страница не найдена</h2>
        <a href="/" className='greenlink'>На главную</a>
      </div>
    )
  }
}

export default Page404