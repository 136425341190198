import React, { Component } from 'react'
import { Row, Card, Col, Button } from 'react-bootstrap';
import Item from "./Item";
import {PRODUCTS} from "../../itemsArray";


export class Items extends Component {
  render() {

    return (

      <div className='content'>

        <Row xs={1} md={4} className="g-4">

        {this.props.items.map((product)=>(
            <Item onShowItem={this.props.onShowItem} key={product.id} data={product}/>
        ))}
        </Row>

      </div>

    )
  }
}

export default Items