import React, { Component } from 'react'

export class Categories extends Component {
    constructor(props){
        super(props)
        this.state = {
            categories: [
                {
                    key: 'all',
                    name: 'Все игры'
                },
                {
                    key: 'games-offline',
                    name: 'Настольные игры'
                },
                {
                    key: 'games-online',
                    name: 'Игры-онлайн'
                },
                {
                    key: 'games-app',
                    name: 'Мобильные приложения'
                },
                {
                    key: 'books',
                    name: 'Книги'
                },
                {
                    key: 'souveneers',
                    name: 'Сувениры'
                }
            ]
        }
    }
    render() {
    return (
      <div className='categories'>
        {this.state.categories.map(el => (
            <div key={el.key} onClick={()=>this.props.chooseCategory(el.key)}>{el.name}</div>
        ))}
      </div>
    )
  }
}

export default Categories