import React from 'react'
import {Link} from "react-router-dom";
import {ShoppingCart} from "phosphor-react"
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import "./navbar.css"

export const Navbar1 = () => {
  return (
   
    <header>
    <Navbar id="navsecond" className="mb-3">
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          {/* <Navbar.Collapse id="basic-navbar-nav"> */}
            <Nav className="flex-grow-1 pe-3 menu">
    {/* <div className="navbar"></div> */}
    <div className="links">
    <Link className="link" to="/">ГЛАВНАЯ</Link>
    <Link className="link" to="/catalogue">КАТАЛОГ ИГР</Link>
    <Link className="link" to="/games-online">ОНЛАЙН-ИГРЫ</Link>
    <Link className="link" to="/payment">ДОСТАВКА И ОПЛАТА</Link>
    <Link className="link" to="/about">О НАС</Link>
    
    </div>    
    {/* </div> */}
    </Nav>
    {/* </Navbar.Collapse> */}
    </Navbar>
</header>

  )
}
