import React, { useContext } from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { FaLongArrowAltLeft} from "react-icons/fa";



import {useNavigate} from 'react-router-dom';

export const BackButton = () => {

  const navigate = useNavigate()
  
  return (
    <div>     
              <Button type="button" className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" onClick={()=>navigate("/")}> <FaLongArrowAltLeft />&nbsp;&nbsp;&nbsp;Продолжить покупки</Button>
 
      </div>
    
  
  )
}

export default BackButton

