import React, { Component } from 'react'
import {useNavigate} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import { FaHome} from "react-icons/fa";
import "./cart.css";

export const Thanks = () => {
    
    const navigate = useNavigate()
 {
    return (
      <div className="block">
      
      <p>Спасибо за заказ!</p>

      <p>Мы свяжемся с Вами в ближайшее время для уточнения деталей и оплаты.</p>

      <button className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" onClick={()=>navigate("/")}><FaHome />&nbsp;&nbsp;&nbsp;На главную</button>

      </div>
    )
  }
}

export default Thanks