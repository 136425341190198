import React, { Component, useContext } from 'react'
import {Link} from "react-router-dom";
import {ShoppingCart} from "phosphor-react"
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
// import {getSum} from "../pages/cart/customer-info"
import {ShopContext} from '../context/shop-context';
import "./navbar.css";

export const Navbar2 = () => {

  const {sum} = useContext(ShopContext);
  return (

   
    <header>

    <Navbar id="navmain" expand="lg" className="mb-3">
      <Navbar.Brand id="header" href="/"><br></br><b>ЭКОИГРЫ</b> <br></br><a href="https://baikalinter.org/" className="ecocenterlink"><p className="break-link">проект Байкальского <br></br>Интерактивного <br></br>Экологического Центра</p></a>
          </Navbar.Brand>
            <Nav className="justify-content-end flex-grow-1 pe-3 menu">
    <div className="links">
    <Link to="/cart"> <ShoppingCart className="shop-cart-button" size={32}/> </Link><p className={sum==0 ? 'cart-number-zero' : 'cart-number' }>{sum} </p>
    </div>    
    </Nav>
    </Navbar>
</header>

  )
}
