import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { FaWhatsapp, FaVk, FaEnvelope, FaGlobe } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';

export class About extends Component {
  render() {
    return (
      <div className="block">
                <Helmet>
            <title>Байкальский интерактивный экологический центр - производим и продаем экоигры</title>
            <link rel="canonical" href="https://naturegames.ru/about" /> 
            <meta name="description" content="На нашем сайте представлены несколько из развивающих настольных игр по краеведению, биологии и экологии." />
            {/* <meta name="title" content="Мобильное приложение Возьми в лес" /> */}
             <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />   
           </Helmet>
      
      
      <div>
        <p><b>«Байкальский интерактивный экологический центр»</b> - образовательно-просветительский проект.  Наши основные принципы - экологичность и интерактивность.</p>

        <p>На нашем сайте представлены несколько из развивающих настольных игр по краеведению, биологии и экологии. Среди них, <b>«Возьми в лес»</b>, <b>«Сортируй»</b>, <b>«Жизнь без отходов»</b>, <b>«Путешествие вокруг Байкала»</b> и <b>«Экослед товара»</b> - это собственные разработки.</p>

        <p>Чтобы ознакомиться с полным перечнем игр и их наличием – напишите нам!</p>

        <h3>Контакты:</h3>

<div className='contacts2-about'>
        <div className='contacts-about'>
<p><FaWhatsapp className="social" size={30}/> </p>
        <p><b>Телефоны </b></p> 
        <p><a href="tel:+7 (914) 908-74-15">8(914)908-74-15</a> (Анна)</p>
        <p>Пожалуйста, звоните с 10:00 до 18:00   </p>
        <p>по Иркутскому времени</p>
          <p>(разница с Москвой + 5 часов)</p>
        </div>
        <div className='contacts-about'>
        <p><a href="https://wa.me/79149087415"><FaWhatsapp className="social" size={30}/></a></p>
        <p><b>Whatsapp</b> </p>
         <p><a href="https://wa.me/79149087415">+7(914)908-74-15</a></p> 
        </div>
        <div className='contacts-about'>
        <p><FaEnvelope className="social" size={30}/></p>
        <p><b>Электронная почта</b> </p>
        <p> <a href='mailto:baikalinter2014@gmail.com'>baikalinter2016@gmail.com</a></p> 
        </div>
        <div className='contacts-about'>
          <p><FaVk className="social" size={30}/></p>
        <p><b>ВКонтакте</b></p> 
        <p> <a href='https://vk.com/baikalecocenter'>vk.com/baikalecocenter</a></p>
        </div>
        <div className='contacts-about'>
          <p><FaGlobe className="social" size={30}/></p>
        <p><b>Web-site</b></p> 
        <p> <a href='https://baikalinter.org'>https://baikalinter.org</a></p>
        </div>

        

        </div>

        <div>
          <p> </p>
       <p> <h3>Реквизиты:</h3></p> 
       <p> ИНН/КПП 3812152605/381201001</p> 
       <p>ОГРН 1143850000062</p> 
       <p>Юридический адрес: 664017, г. Иркутск, ул. Лермонтова, 140</p> 
        </div>

        
</div>
      </div>
    )
  }
}

export default About