import React, { useContext } from 'react'
import { ShopContext } from '../../context/shop-context';
import itemsArray, { PRODUCTS } from "../../itemsArray";
import { CartItem } from './cart-item';
import { ListOfItems } from './ListOfItems';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const CustomerInfo = () => {

    const { cartItems, getTotalCartAmount } = useContext(ShopContext);
    const totalAmount = getTotalCartAmount()
    const navigate = useNavigate()
    // var sum = 0;

    const newArray = [];
    const values = [];

    for (const item in cartItems) if (cartItems[item] !== 0) { { values.push(cartItems[item]) } }

//     const getSum = () => {
//         // var sum = 0;
//         sum = values.reduce(function(pv, cv) { return pv + cv; }, 0);
// return sum;
//     }

    const order = PRODUCTS.map((product) => {
        if (cartItems[product.id] !== 0) {
            newArray.push(product.title)
            return <ListOfItems data={product} />;
        }
    })


    const MyComponent = () => {
        return (<div>{order}</div>)
    }


    const items = PRODUCTS.map((product) => {
        if (cartItems[product.id] !== 0) {
            return (<CartItem data={product} />)
        }
    })


    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_3z0pbm9', 'template_8tb2laa', form.current, '_JM8gaYOKr0isfe2b')
            .then((result) => {
                // console.log(result.text);
                navigate("/thanks")
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };

    return (

        <div className="container">
            <div className=" text-center mt-5 ">
                <h1>Заполните ваши данные</h1>
            </div>

            <div className="row ">
                <div className="col-lg-7 mx-auto">
                    <div className="card mt-2 mx-auto p-4 bg-light">
                        <div className="card-body bg-light">

                            <div className="container">
                                <form id="contact-form" ref={form} onSubmit={sendEmail}>

                                    <div className="controls">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="form_name">Имя *</label>
                                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="Иванов Иван" required="required" data-error="Введите имя и фамилию." />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="form_lastname">Телефон *</label>
                                                    <input id="form_lastname" type="phone" name="phone" className="form-control" placeholder="+79507777777" required="required" data-error="Введите свой телефон." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="form_email">Email *</label>
                                                    <input id="form_email" type="email" name="email" className="form-control" placeholder="example@email.ru" required="required" data-error="Введите корректный email." />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="form_need">Адрес доставки (если нужно)</label>
                                                    <input id="form_lastname" type="text" name="address" className="form-control" placeholder="77777, г. Москва, ул. Лермонтова, д.1. кв.10"/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="form_message">Пожелания к заказу</label>
                                                    <textarea id="form_message" name="message" className="form-control" placeholder="Напишите ваши пожелания к заказу" rows="4"></textarea
                                                    >
                                                </div>

                                            </div>

                                            <div>

                                                <br></br>



                                                <div className="cart">
                                                    <div>
                                                        <h2>Ваш заказ:</h2>
                                                    </div>
                                                    <div className="cartItems" name="items">
                                                        {items}

                                                    </div>
                                                </div>

                                                <p>Сумма заказа: {totalAmount} руб</p>
                                               
                                                <p>Обратите внимание, что сумма заказа не включает стоимость доставки (200-400 руб. по России.) Самовывоз из Иркутска - бесплатно.</p>
                                                {/* <p>Ваш заказ:</p>

                                                <div>
                                                    <Row xs={1} md={4} className="g-4">
                                                        <MyComponent />
                                                    </Row>
                                                </div> */}

                                                {/* <p>{values}</p> */}



                                                <input name='amount' type='hidden' value={totalAmount}></input>


                                                {/* <input name='order' type='hidden' value={JSON.stringify(newArray.map(item=> (<p>{item}</p>)))}></input> */}

                                
                                                <input name='values' type='hidden' value={JSON.stringify(values)}></input>

                                                <input name='order' type='hidden' value={JSON.stringify(newArray)}></input>

                                                {/* {JSON.stringify(newArray)} */}

                                                {/* <p>{Object.entries(order)}</p> */}
                                                {/* <p>!{JSON.stringify(newArray)}</p> */}
                                                {/* <p>!{JSON.stringify(values)}</p> */}

                                                <br></br>

                                            </div>

                                            <div className="col-md-12">

                                            <input type="submit" className="tn btn-outline-light btn-send pt-2 btn-block btn-1 btn-for-items" value="Оформить заказ" />

                                            {/* onClick={()=>navigate("/thanks") */}

                                            </div>

                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </div>


    );

}


export default CustomerInfo

