import React, {createContext, useEffect, useState} from 'react'
import {PRODUCTS} from "../itemsArray";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  

 let cart = {};
  for (let i = 1; i < PRODUCTS.length + 1; i++) {
    cart[i] = 0;
  }
  return cart;
};

export const ShopContextProvider = (props) => {
  let amount = 0;
  const currentItems = []

const [cartItems, setCartItems] = useState(getDefaultCart());

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.price;
      }
    }
    return totalAmount;
  };

  const getItemsCount= () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
        totalAmount = totalAmount+1;
      }
    }
    return totalAmount;

  };

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    amount = amount + 1;
    return amount
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    amount = amount - 1;
    return amount
  };

  const updateCartItemCount = (newAmount, itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  };


  const chooseCategory = (category) => {
    if (category === 'all') {
      currentItems = currentItems[cartItems]
      return
    }
  }

  const checkout = () => {
    setCartItems(getDefaultCart());
  };


  const values = [];
  var sum = 0;
  for (const item in cartItems) if (cartItems[item] !== 0) { { values.push(cartItems[item]) } }

    sum = values.reduce(function(pv, cv) { return pv + cv; }, 0);


  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    getItemsCount,
    checkout,
    chooseCategory,
    amount,
    sum,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};