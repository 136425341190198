import React from 'react'
import './recycling_sort_style.css';
import { Helmet } from 'react-helmet';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

export const SortGame = () => {
  return (

    <div>
  <Helmet>
    <title>Экологическая онлайн игра Сортирировка мусора - играть бесплатно</title>
    <meta name="description" content="Предлагаем вам сыграть в игру по сортировке мусора. Отсортируйте разные виды мусора по корзинкам." />  
    <link rel="canonical" href="https://naturegames.ru/sort-memo-game" />   
    <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />
    {/* <meta name="title" content="Экологическая онлайн игра Сортирировка мусора - играть бесплатно" /> */}
  </Helmet>
    
      <h2>Сортировка мусора</h2>

   

<p className='block'>Онлайн игра по сортировке мусора. Разложите соответсвующие виды отходов в корзины по типам: пластик, стекло, бумага, органика, опасные отходы и прочий неперерабатываемый мусор.</p>
<p className='block'><strong>Игра недоступна с мобильного телефона. Чтобы сыграть, зайдите на сайт в браузере со стационарного компьютера.</strong></p>

      {/* <p className="descr">Отсортируйте разные виды мусора по контейнерам</p> */}

<div>
<section className="draggable-elements">
<img src="img/recycling_sort/organic.png" className="draggable" alt="" draggable="true" id="organic"/>
<img src="img/recycling_sort/glas.png" className="draggable" draggable="true" alt="" id="glas"/>
<img src="img/recycling_sort/paper.png" className="draggable" draggable="true" alt="" id="paper"/>
<img src="img/recycling_sort/plastic.png" className="draggable" draggable="true" alt="" id="plastic"/>
<img src="img/recycling_sort/dangerous.png" className="draggable" draggable="true" alt="" id="dangerous"/>
<img src="img/recycling_sort/garbage.png" className="draggable" draggable="true" alt="" id="garbage"/>

</section>

<section className="droppable-elements">
<div className="droppable" data-draggable-id="organic"><span class="not-filled"><img src="img/recycling_sort/organic_bin.png" alt="" style={{visibility:'visible'}}/></span><br/>Органика</div>
<div className="droppable" data-draggable-id="paper"><span><img class="not-filled" src="img/recycling_sort/paper_bin.png" alt=""/></span><br/>Бумага</div>
<div className="droppable" data-draggable-id="plastic"><span><img class="not-filled" src="img/recycling_sort/plastic_bin.png" alt=""/></span><br/>Пластик</div>
<div className="droppable" data-draggable-id="glas"><span><img class="not-filled" src="img/recycling_sort/glas_bin.png" alt=""/></span><br/>Стекло</div>
<div className="droppable" data-draggable-id="dangerous"><span><img class="not-filled" src="img/recycling_sort/dangerous_bin.png" alt=""/></span><br/>Опасные отходы</div>
<div className="droppable" data-draggable-id="garbage"><span><img class="not-filled" src="img/recycling_sort/garbage_bin.png" alt=""/></span><br/>Неперерабатываемый мусор</div>

</section>


</div>
<br></br>


<div className='block'>
<h2>Как сортировать мусор для переработки</h2>
<p>Сортировка мусора - это важный аспект поддержания экологической устойчивости. Вот короткая инструкция, которая поможет вам правильно сортировать различные виды мусора:</p>
<h4>Разделение</h4>
<p>Начинайте с разделения мусора на две основные категории - <b>перерабатываемые</b> и <b>неперерабатываемые отходы</b>. К перерабатываемым отходам относятся стекло, бумага, металл и пластик, который можно переработать.</p>
<h4>Стекло</h4> 
<p>Стекло можно сортировать по цвету - прозрачное, зеленое, коричневое или голубое. Каждый цвет стекла следует собирать отдельно. Стоит уточнять в пункте переработки, принимают ли там битое стекло.</p>
<h4>Бумага</h4> 
<p>Бумагу следует сортировать на белую и цветную, а также на газеты, журналы и картон. Не относятся к бумаге и соответственно не перерабатываются кассовые чеки, салфетки и бумажные стаканчики, так как содержат в своем составе пластик.</p>
<h4>Металл</h4> 
<p>Все металлические предметы можно собирать вместе, включая металлические предметы, алюминиевые и жестяные банки.</p>
<h4>Пластик</h4> 
<p>Пластиковые отходы следует сортировать в соответствии с маркировкой на дне контейнера (номер и расшифровка). Обычно это следующие типы: </p>
<ul>
  <li>1 - полиэтилентерефталат (PETЕ);</li>
  <li>2 - высокоплотный полиэтилен (PEHD или HDPE);</li>
  <li>3 - поливинилхлорид (PVC);</li>
  <li>4 - низкоплотный полиэтилен (LDPE или PEBD);</li>
  <li>5 - полипропилен (PP);</li>
  <li>6 - полистирол (PS);</li>
  <li>7 - обозначает остальные категории пластика (OTHER).</li>
</ul>

<p>Стоит уточнять в пунктах приема, какие виды пластика они принимают к переработке. Обычно это 1, 2, 3 и 4. Иногда принимают 5. Часто не принимают пластик типов 6 и 7.</p>

<h4>Электроника и опасные отходы.</h4>
  <p>Сейчас в России есть множество пунктов приема батареек. Это очень опасные отходы, попадая в почву батарейка отравляет ее на долгие годы, поэтому их обязательно стоит относить в пункты приема.</p>
   <p>Ртутные лампы относятся к первому классу опасности и требует специальной утилизации, их принимают в специальных  пунктах приема и некоторых магазинах. Остальные лампы не представляют такой опасности для окружающей среды, хотя содержат полезные элементы и могут быть также переработаны. Отходы электроники (например, старые телефоны, компьютеры и бытовая техника) следует сдавать в специальные пункты приема. </p>
<h4>Органика</h4> 
<p>Эти отходы можно использовать в качестве компоста, если у вас есть дача, либо сушить и вывозить в лес. В крайнем случае выбрасывать отдельно от остального мусора.</p>

<p>Помните, что мусор на переработку лучше сдавать в чистом виде. Вымойте и высушите бутылки, консервные банки и другие грязные отходы.</p>

<h4>Неперерабатываемые отходы и сложный для переработки типы мусора:</h4>
<p>К таким отходам относятся смешанные отходы, содержащие разные типы материалов, например тетрапак, состоящий из слоев картона, пластика и алюминиевой фольги или бумажные стаканчики для кофе, содержащие слой пластика, зубные щетки и другие... 
  Кассовые чеки состоят из термобумаги и часто не принимаются к переработке. Сдать такие отходы можно на различных акциях по сбору мусора и в специализированных пунктах приема.</p>

<h3>Как сделать жизнь более экологичной и уменьшить объем мусора:</h3>
<p>Помимо сортировки мусор и сдачи на переработку, отличной идеей является сокращение объема потребляемого мусора. Вот несколько советов для уменьшения отходов:</p>

<ul>
  <li>Используйте многоразовую сумку для покупок вместо одноразовых пакетов. Используйте фруктовки и сеточки для офощей и фруктов вместо пластиковых пакетиков.</li>
  <li>Старайтесь покупать товары в упаковке, которую можно переработать или использовать повторно.</li>
  <li>Избегайте использования одноразовой посуды и пластиковых столовых приборов. Используйте свои контейнеры и просите в кофейне налить напитки в свою термокружку.</li>
  <li>Вместо того чтобы выбрасывать старые вещи, попробуйте их отремонтировать или подарить им вторую жизнь. Продавайте старые вещи.</li>
  <li>Собирайте и сдавайте на переработку бумагу, стекло, металл и пластиковые отходы.</li>
  <li>Не выбрасывайте электронику и опасные отходы вместе с обычным мусором. Сдавайте их в специальные пункты приема.</li>
</ul>
</div>
   <div>
         {AddLibrary(
  'scripts/recycling_sort.js')}
  </div> 
  
  </div>
 

  );
}
