import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../components/BackButton";
import BuyButton from "../../components/BuyButton";
import GoToCartButton from "../../components/GoToCartButton";
import { Helmet } from 'react-helmet-async';

export class BaikalPutanica extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <title>Книга раскарска Байкальская путаница</title>
    <meta name="description" content="На страницах раскраски дети познакомятся с десятью обитателями Байкальского региона и смогут сравнить их с собой." />
    <link rel="canonical" href="https://naturegames.ru/baikal-putanica" />   
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    {/* <meta name="title" content="Книга раскарска Байкальская путаница" /> */}     
    </Helmet>
      <div className="block">
                   
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог товаров</a></li>
          <li className="breadcrumb-item">Книги и пособия</li>
          <li className="breadcrumb-item active" aria-current="page">Байкальская путаница</li>
        </ol>
      </nav>
      <div itemscope itemtype='http://schema.org/Product'>

      <h1 itemprop="name">Байкальская путаница</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button> */}
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/baikal-putanica/baikal-putanica.jpg" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
    <div className="carousel-item">
      <img src="img/baikal-putanica/bp-3.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/baikal-putanica/bp-2.jpg" className="d-block w-100" alt="..."/>
    </div>
    {/* <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-4.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-5.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-6.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-7.jpg" className="d-block w-100" alt="..."/>
    </div> */}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6">    
  <p><b>Книга раскарска</b></p>
  <p itemprop="description">На страницах раскраски дети познакомятся с десятью обитателями Байкальского региона и смогут сравнить их с собой. А раскрасив и разрезав странички можно будет поиграть и пофантазировать - собрать новых неведомых науке зверей, придумать им имена и фантастические способности!</p>
  
  <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">200</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>

  <br></br>
  <BuyButton ItemId={10} />
  <GoToCartButton/>
  <BackButton/>

  <br></br>
 <p><b>Познокомьтесь с другими книгами этой категории:</b></p> 
 <p><a href="https://naturegames.ru/baikal-azbuka" className='greenlink'>Книга для детей "Байкальская азбука"</a></p>
 
  </div>

 
  </div>
</div>
      </div>
      </div>
    )
  }
}

export default BaikalPutanica