export const PRODUCTS=[
    {
        id: 1,
        title: 'Возьми в лес - лето',
        img: 'RajmLOtdTQY.jpg',
        desc: '40 карточек с заданиями, опытами и играми, которые можно взять на прогулку в парк, лес, на речку. Карточки не дадут заскучать ни одному юному исследователю!',
        category: ['games-offline', 'games-app'],
        price: '800.00',
        link: 'forest-cards-summer',
        gameLink: 'forest-cards',
        appLink: 'forest-cards-app'
      },
      {
        id: 2,
        title: 'Возьми в лес - зима',
        img: 'take-to-forest-winter.jpg',
        desc: 'Карточки не дадут заскучать зимой: игры, опыты, творческие мастер-классы ждут юных исследователей природы внутри небольшой коробочки.',
        category: ['games-offline', 'games-app'],
        price: '800.00',
        link: 'forest-cards-winter',
        appLink: 'forest-cards-app'
      },
      {
        id: 3,
        title: 'Сортируй',
        img: 'sort-game.jpg',
        desc: 'Цель игры - собрать как можно больше пар карточек. В каждой паре должна быть карточка с баком для отходов и карточка с каким-либо предметом.',
        category: ['games-offline', 'games-online'],
        price: '650.00',
        link: 'sortiruy',
        gameLink: 'sort-memo-game',
        appLink: null
      },
      {
        id: 4,
        title: 'Мемо Птицы',
        img: 'birds.jpg',
        desc: 'Онлайн игра "мемо карточки" c фотографиями птиц, которых можно встретить в городе Иркутске и в районе озера Байкал.',
        category: ['games-online'],
        price: '0.00',
        link: 'birds-memo',
        gameLink: 'birds-memo',
        appLink: null
      },
      // {
      //   id: 5,
      //   title: 'Звёзды в ладошке',
      //   img: 'stars.jpg',
      //   desc: 'Набор из деревянных карточек с самыми распространёнными созвездиями. + Буклет с доп материалами.',
      //   category: ['games-offline'],
      //   price: '1150.00',
      //   link: 'zvezdy-na-ladoshke',
      //   gameLink: null,
      //   appLink: null
      // },
      {
        id: 5,
        title: 'Пустое Ведро',
        img: 'pustoe-vedro.jpg',
        desc: 'Карточки-магниты для изучения экологических тем: осознанное потребление, переработка отходов и многоразовые аналоги.',
        category: ['games-offline'],
        price: '1000.00',
        link: 'empty-bucket',
        gameLink: null,
        appLink: null
      },
      {
        id: 6,
        title: 'Сортировка мусора',
        img: 'garbage_logo.png',
        desc: 'Онлайн игра по сортировке мусора. Разложите соответсвующие виды отходов в корзины по типам: пластик, стекло, бумага, органика...',
        category: ['games-online'],
        price: '0.00',
        link: 'sort-game',
        gameLink: 'sort-game',
        appLink: null
      },
      // {
      //   id: 8,
      //   title: 'Жизнь без отходов',
      //   img: 'nowaste.png',
      //   desc: 'Настольная игра с 100 вопросами и ответами по теме «Бытовые твердые отходы». В игре могут принимать участие от 2-х до 4-х игроков или команд. Экологическая игра позволяет в увлекательной, интересной форме получить знания и навыки утилизации бытовых и опасных отходов.',
      //   category: ['games-offline'],
      //   price: '900.00',
      //   gameLink: null,
      //   appLink: null
      // },
      {
        id: 7,
        title: 'Экослед товара',
        img: 'ecosled.png',
        desc: 'Игровое пособие для изучения жизненного цикла товара от добычи сырья до момента превращения его компонентов в отходы.',
        category: ['games-offline'],
        price: '700.00',
        link: 'ecosled',
        gameLink: null,
        appLink: null
      },
      {
        id: 8,
        title: 'Байкальская Азбука',
        img: 'baikal-asbuka.jpg',
        desc: 'Азбука, в которой с каждой буквой связаны географичекие объекты и представители животного и растительного мира Байкала.',
        category: ['books'],
        price: '350.00',
        link: 'baikal-azbuka',
        gameLink: null,
        appLink: null
      },
      {
        id: 9,
        title: 'Байкальская путаница',
        img: 'baikal-putanica.jpg',
        desc: 'Книга-раскраска с животными - обитателями Байкальского региона',
        category: ['books'],
        price: '200.00',
        link: 'baikal-putanica',
        gameLink: null,
        appLink: null
      },
];

export default {
  PRODUCTS
}