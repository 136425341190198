import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../components/BackButton";
// import BuyButton from "../../components/BuyButton";
import { Helmet } from 'react-helmet-async';

export class SiteMap extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <meta name="title" content="Экологические игры - карта сайта" />
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    <meta name="description" content="Сорок карточек не дадут заскучать зимой: игры, опыты, творческие мастер-классы ждут юных исследователей природы внутри небольшой коробочки." />
    <title>Возьми в Лес (Зима) - Настольная экологическая игра - карточки с заданиями для детей</title>
    <link rel="canonical" href="https://naturegames.ru/site-map" />
    </Helmet>
      <div className="block">




        <h1>Карта Сайта</h1>
        <ol><li><a href="https://naturegames.ru/">Главная</a>
          
            <ol><li><a href="https://naturegames.ru/catalogue">Каталог игр</a>
            
            <ol><li><a href="https://naturegames.ru/catalogue">Настольные Игры и пособия</a>
              
              <ol>
              <li><a href="https://naturegames.ru/forest-cards-summer">Возьми в лес - лето</a></li>
              <li><a href="https://naturegames.ru/forest-cards-winter">Возьми в лес - зима</a></li>
              <li><a href="https://naturegames.ru/sortiruy">Сортируй</a></li>
              <li><a href="https://naturegames.ru/zvezdy-na-ladoshke">Звёзды в ладошке</a></li>
              <li><a href="https://naturegames.ru/empty-bucket">Пустое ведро</a></li>
              <li><a href="https://naturegames.ru/ecosled">Экослед товара</a></li>
              <li><a href="https://naturegames.ru/baikal-azbuka">Байкальская Азбука</a></li>
              <li><a href="https://naturegames.ru/baikal-putanica">Байкальская путаница</a></li>
              </ol>

              </li>
            
            <li><a href="https://naturegames.ru/games-online">Игры-онлайн</a>

              <ol>            
              <li><a href="https://naturegames.ru/sort-memo-game">Сортируй</a></li>
              <li><a href="https://naturegames.ru/birds-memo">Мемо Птицы</a></li>            
            <li><a href="https://naturegames.ru/sort-game">Сортировка мусора</a></li>
            </ol>

            </li></ol>
            
            </li></ol>
            <li><a href="https://naturegames.ru/payment">Доставка и оплата</a></li>
            <li><a href="https://naturegames.ru/about">О Нас</a></li>
            </li>          
        </ol>

        <div>Экологические игры и пособия. Играть онлайн или купить в каталоге экологических игр. Наши эко-игры подходят детям различного возраста для познания окружающего мира, развивают наблюдательность и творческие способности, а также формируют экологические привычки и ответственное отношение к природе. 
          Игры можно использовать как в для самостоятельных занятий с детьми, так и для занятий по экологии, проведения экскурсий, мастер-классов и презентаций по экологии.</div>
        


     
        

        

      </div>
      </div>
    )
  }
}

export default SiteMap