import React, {useContext} from 'react';
import {ShopContext} from '../../context/shop-context';

export const ListOfItems = (props) => {
    const {id, title, price, img} = props.data;
    const {cartItems, addToCart, removeFromCart, updateCartItemCount} = useContext(ShopContext);
  return (
<div>                <p>{title}</p>
    </div>
  );
}