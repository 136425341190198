import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { FaWhatsapp, FaVk, FaEnvelope, FaGlobe } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';

export class AboutSiteDeveloper extends Component {
  render() {
    return (
      <div className="block">
                <Helmet>
            <title>Разработка сайта</title>
            <link rel="canonical" href="https://naturegames.ru/razrabotka" /> 
            <meta name="description" content="Разработка сайта" />
             <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />   
           </Helmet>      
      
      <div>
        <h1><b>Разработка сайта</b></h1>

        <p><a href="/" className='greenlink'>naturegames.ru</a></p>

        <p><strong>Дмитрий Зарецкий</strong></p>

        <p>Разработка сайтов, мобильных Android-приложений, поддержка интернет-магазинов, SEO</p>

       <p><a href='mailto:baikalinter2014@gmail.com' className='greenlink'><FaEnvelope className="social" size={30}/></a><a href='mailto:baikalinter2014@gmail.com' className='greenlink'><span className="linkemail">dzaretcki@gmail.com</span></a></p>


        
</div>
      </div>
    )
  }
}

export default AboutSiteDeveloper
