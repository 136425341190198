import React from 'react'
import { FaVk } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaEnvelope, FaGlobe } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.css';


const currentDate = new Date();
const year = currentDate.getFullYear();

export default function Footer() {
  return (
    <footer id="footer" className="white">


<div id="footer" className='contacts2'>
        <div className='col-3'>
        <br></br>
        <h5>ЭКОИГРЫ</h5>
        <p>Авторские игры и пособия <br></br> для экологичского просвещения <br></br> и детского образования</p>

        </div>
        <div className='col-3'>
        <br></br>
        <h5>Сайт</h5>
        <p><a href="/">Главная</a></p>
        <p><a href="/catalogue">Каталог</a></p>
        <p><a href="/cart">Корзина</a></p>
        </div>
        
        <div className='col-3'>
        <br></br>
        <h5>Каталог</h5>
        <p><a href="/catalogue">Настольные Игры</a></p>
        <p><a href="/catalogue">Книги и пособия</a></p>
        <p><a href="/games-online">Онлайн-Игры</a></p>

        </div>
        <div className='col-3'>
        <br></br>
        <h5>Покупателю</h5>
        <p><a href="/payment">Доставка и оплата</a></p>
        <p><a href="/">Политика конфиденциальности</a></p>
        <p><a href="/about">Контакты</a></p>
        <p><a href="/site-map">Карта сайта</a></p>
        </div>

        

        </div>


<br></br>
    <a href="https://m.vk.com/baikalecocenter/"><FaVk className="social" size={30}/></a>
    <a href="tel:+7 (914) 908-74-15"><FaWhatsapp className="social" size={30}/></a>
    <a href="mailto:baikalinter2014@gmail.com"><FaEnvelope className="social" size={30}/></a>
    <p><a href="/developer" className='greenlink'>Разработка сайта - Dmitry Zarecki</a></p>
    <br></br>

  </footer>
  )
}
