import React from 'react'
import './recyclingstyle.css';
import { Helmet } from 'react-helmet';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

export const SortMemoGame = () => {
  return (
    <div>
    <Helmet>
    <title>Экологическая онлайн игра Сортируй - играть бесплатно</title>
    <meta name="description" content="Предлагаем вам сыграть в демо-версию игры Сортируй. Для начала игры нажмите на одну из карточек." />
    <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />
    <link rel="canonical" href="https://naturegames.ru/sort-memo-game" /> 
    {/* <meta name="title" content="Экологическая онлайн игра Сортируй - играть бесплатно" /> */}
    </Helmet>
    
    <div className="recyclebody">
   
<br></br>
<h2>Игра Сортируй</h2>

    <div>
      <p className="descr">Предлагаем вам сыграть в демо-версию игры "Сортируй". Для начала игры нажмите на одну из карточек.</p>
      <p>Если игра не загружается - перезагрузите страницу</p>
       <h3>Ходов: <span className="playerLivesCount"></span></h3>
       <section className="reciclingSection" id="reciclingSection"></section>
       </div>

      
    <div>
         {AddLibrary(
  'scripts/recycling_memo.js')}
  </div>
  

  {/* <br></br>

<br></br>

<p><b>Сыграйте в <a href="https://naturegames.ru/birds-memo">"мемо-игру Птицы"</a></b></p>  */}
  
  </div>


  </div>
   );
}