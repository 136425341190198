import React from 'react'
import './birdystyle.css';
import { Helmet } from 'react-helmet';

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

export const BirdsMemo = () => {
  //  const script = document.createElement("script");
  //  script.src = "../scripts/memo_birds.js";
  //  script.async = true;
  //  document.body.appendChild(script);
  // <script type="text/javascript" src="memo_birds.js"></script>
   return (
     <><div>
       <Helmet>
         <title>Игра Мемо птицы - играть онлайн</title>
         <meta name="description" content="Онлайн игра мемо карточки c фотографиями птиц, которых можно встретить в городе Иркутске и в районе озера Байкал. Переворачивай карточки - ищи совпадения и узнавай названия птиц!" />
         <link rel="canonical" href="https://naturegames.ru/birds-memo" />
         <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />
       </Helmet>
     </div><div>

         {/* <div className="birdmemostart"> */}
         <h2>Онлай игра "Мемо Птицы"</h2>

         <div className='block'>
           <p>Онлайн игра мемори c изображениями птиц, которых можно встретить в городе Иркутске и в районе озера Байкал. Игры по приницпу мемо в России чаще всего известны под названием "найди пару", такие игры тренируют память и внимательность.</p>
           <h3>Как играть:</h3>
           <p>Переворачивайте карточки - ищите совпадения и узнавайте названия птиц! Вам дается 30 ходов - это 30 попыток, чтобы перевернуть все картчоки. Игра тренирует память и помогает запомнить навания нескольких видов птиц, обитающих черте города Иркутска.</p>
           <p>Если игра не загружается - перезагрузите страницу</p>
         </div>

         {/* </div> */}

         <div className="birdmemobody" id="birdmemobody">
           <h3>Ходов: <span class="playerLivesCount"></span></h3>
           <section id="birdssection"></section>
         </div>

         <div className='block' id='block'> <p>Автор фотографий - Ольга Михалева. Бёрдводчер и гид, автор канала о птицах Иркутска.</p>

         <br></br>
         
         <h3>Список птиц из игры</h3>

         <ul>
          <li>Озерная чайка</li>
          <li>Дубонос</li>
          <li>Обыкновенная горихвостка</li>
          <li>Дубровник</li>
          <li>Большая синица</li>
          <li>Московка</li>
          <li>Малый зуек</li>
          <li>Оляпка</li>
          <li>Снегирь</li>
          <li>Свиристель</li>
          <li>Желтобровая овсянка</li>
          <li>Сибирская горихвостка</li>
         </ul>
         
         <p><b>Сыграйте также в <a href="https://naturegames.ru/sort-memo-game" className='greenlink'>"мемо-игру Сортируй"</a></b></p> 
   

         
         </div>




         <div>

           {AddLibrary(
             'scripts/memo_birds.js')}
         </div>

       </div></>
     
  );


}