import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BuyButton from "../../components/BuyButton";
import BackButton from "../../components/BackButton";
import GoToCartButton from "../../components/GoToCartButton";
import { Helmet } from 'react-helmet-async';

export class Sortiruy extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <title>Игра "Сортируй" - купить экологические игры</title>
    <meta name="description" content="Настольная экологическая игра 'Сортируй'- карточки для детей. Купить или играть онлайн" />  
    <link rel="canonical" href="https://naturegames.ru/sortiruy" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    {/* <meta name="title" content="Экоигра 'Сортируй'" /> */}
    </Helmet>
      
      <div className="block">
        
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог игр</a></li>
          <li className="breadcrumb-item">Настольные игры</li>
          <li className="breadcrumb-item active" aria-current="page">Сортируй</li>
        </ol>
      </nav>
      <div itemscope itemtype='http://schema.org/Product'>

        <h1 itemprop="name">Сортируй</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/sort/sort-1.jpg" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
    <div className="carousel-item">
      <img src="img/sort/sort-2.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/sort/sort-3.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/sort/sort-4.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/sort/sort-5.jpg" className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6">    
  <p><b>Настольная экологическая игра - карточки для детей</b></p>
  <p itemprop="description">Настольная игра "Сортируй" создана на основе идеи игр мемори, только с экологическим уклоном. Цель игры - собрать как можно больше пар карточек. В каждой паре должна быть карточка с баком для отходов и карточка с каким-либо предметом (видом мусора). Это могут быть стекло, бумага, пластик или органические отходы. Причём цвет карточек должен быть одинаковым - это будет означать, что отходы попали именно туда, куда нужно.</p>
  <p>Игра будет полезна для детей, чтобы объяснить важность сортировки и переработки отходов. Она может быть использована для занятий по экологии. Игры мемори отлично тренирует память и внимательность. </p>
  <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">600</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>

  
  <br></br>
  <BuyButton ItemId={3}/>
  <GoToCartButton/>
  <BackButton/>

  <br></br>

  <p><b>Сыграйте в <a href="https://naturegames.ru/sort-memo-game" className='greenlink'>онлайн-версию игры "Сортируй"</a></b></p> 

 <br></br>

  <p><b>Познокомьтесь с другими играми этой категории:</b></p> 
 <p><a href="https://naturegames.ru/empty-bucket" className='greenlink'>Экологическая игра Пустое Ведро"</a></p>
 <p><a href="https://naturegames.ru/ecosled" className='greenlink'>Экологическая игра Экослед товара"</a></p>
  </div>

  </div>
</div>
      </div>
      </div>
    )
  }
}

export default Sortiruy