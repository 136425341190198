import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet-async';

export class ForestCards extends Component {
  render() {
    return (
      <div className="block">
          <Helmet>
            <title>Мобильное приложение Возьми в лес</title>
            <link rel="canonical" href="https://naturegames.ru/forest-cards-app" /> 
            <meta name="description" content="80 карточек для для занятий с детьми на природе в твоем телефоне. Мобильное приложение находится в разработке." />
             <meta name="keywords" content="Экологические, игры, по экологии, онлайн, экоигры" />   
           </Helmet>
        
        <h1>Мобильное приложение "Возьми в Лес"</h1><img className='imgApp' src="img/forest_cards_phone_2.png" alt="" width="30%"/>
        <p> 80 карточек для для занятий с детьми на природе в твоем телефоне</p>

        <p><b>Мобильное приложение находится в разработке. Следите за обновлениями!</b></p>
       
        <ul>
          <li>Развивает воображение</li>
          <li>Прививает любовь к природе</li>
          <li>Расширяет кругозор</li>
          <li>Повышает экологическое сознание</li>
        </ul>
          
          <h3>Авторы</h3>

          <p>Анна Огородникова - автор материалов</p>
          <p>Зарина Муллагалеева - автор материалов</p>
          <p>Артем Берне - дизайн</p>
          <p>Дмитрий Зарецкий - разработка приложения</p>
        
      </div>
    )
  }
}

export default ForestCards