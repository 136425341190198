import React, { useContext } from 'react'
import { FaShoppingCart, FaGamepad, FaAndroid, FaArrowUp } from "react-icons/fa";
import { Row, Card, Col, Button } from 'react-bootstrap';
import {ShopContext} from "../../context/shop-context"
import {useNavigate} from 'react-router-dom';

export const ItemCatalogue = (props) => {
  const {id, title, desc, img, price, category, link, gameLink, appLink} = props.data;
  const{addToCart, cartItems} = useContext(ShopContext);
  const cartItemAmount = cartItems[id]
  const navigate = useNavigate()

    return (
        
<div>
<Card xs={1} md={1} className='g-4'> 

<Row className='g-4'>
  <Col md={2}>
  <Card.Img className="itemImg" variant="top" src={"./img/" + img} onClick={()=>navigate("/"+link)}/>  
  </Col>

  <Col md={6}>

    <Card.Body>
  <Card.Title onClick={()=>navigate("/"+link)}>{title}</Card.Title>

  <Card.Text>{desc}</Card.Text>

  <Card.Text>Цена: {price} руб.</Card.Text>


            </Card.Body>
  </Col>

<Col  md={4}>  


{ <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" onClick={()=>navigate("/"+link)}>          
              <FaArrowUp />&nbsp;&nbsp;&nbsp;Подробнее
            </Button>}


{ (category.includes('games-offline') || category.includes('books') || category.includes('souveneers')) && <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" 
            //  onClick={() => this.props.onAdd(this.props.item)}
            onClick={() => addToCart(id)}>         
              <FaShoppingCart />&nbsp;&nbsp;&nbsp;Купить {cartItemAmount>0 && <> ({cartItemAmount})</>}
            </Button>}
             

            { (category.includes('games-online')) && <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" onClick={()=>navigate("/"+gameLink)}>          
              <FaGamepad />&nbsp;&nbsp;&nbsp;Играть Онлайн
            </Button>}

            { (category.includes('games-app')) && <Button type="button" className="btn btn-outline-light btn-lg download-btn btn-1 btn-for-items" onClick={()=>navigate("/"+appLink)}>          
              <FaAndroid />&nbsp;&nbsp;&nbsp;Приложение
            </Button>}</Col>
  </Row>
  </Card>

  <br></br>

  </div>
       
    );
  
};

export default ItemCatalogue