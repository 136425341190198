import React, { Component } from 'react'

export class Warning extends Component {
  render() {
    return (
      <div className='warning'>
            <h6>Сайт находится в разработке! Для уточнения наличия и заказа товаров обращайтесь по watsapp <a href="tel:+7 (914) 908-74-15"> +7 (914) 908-74-15 </a>или по e-mail <a href="#">baikalinter2014@gmail.com</a></h6>
      </div>
    )
  }
}

export default Warning