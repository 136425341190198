import React, { Component } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../components/BackButton";
import BuyButton from "../../components/BuyButton";
import GoToCartButton from "../../components/GoToCartButton";
import { Helmet } from 'react-helmet-async';

export class ForestCardsSummer extends Component {
  render() {
    return (
      <div>
    <Helmet>
    <title>Возьми в Лес (Лето) - карточки с заданиями для детей</title>   
    <meta name="description" content="40 карточек с заданиями, которые можно взять на прогулку в парк, лес, на речку. Карточки не дадут заскучать ни одному юному исследователю! " />
    <link rel="canonical" href="https://naturegames.ru/forest-cards-summer" /> 
    <meta name="keywords" content="Экологические, игры, по экологии, купить, экоигры" />
    {/* <meta name="title" content="Возьми в Лес (Лето) - купить настольную экологическую игру - карточки с заданиями для детей" />  */}
    </Helmet>
      <div className="block">
        
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/" class="text-dark">Главная</a></li>
          <li className="breadcrumb-item"><a href="/catalogue" className="text-dark">Каталог игр</a></li>
          <li className="breadcrumb-item">Настольные игры</li>
          <li className="breadcrumb-item active" aria-current="page">Возьми в Лес</li>
        </ol>
      </nav>

<div itemscope itemtype='http://schema.org/Product'>

        <h1 itemprop="name">Возьми в Лес - Лето</h1>

        <br></br>
        
        <div className="row">
  <div className="col-md-6 mb-3">
    
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="img/forest-cards-summer/fc-1.jpg" className="d-block w-100" alt="..." itemprop="image"/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-2.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-3.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-4.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-5.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-6.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="img/forest-cards-summer/fc-7.jpg" className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  </div>
  <div className="col-md-6 mb-3">    
 <p itemprop="description"><b>Карточки для детей с заданиями и играми на природе</b></p>
 <p>Игра "Возми в лес" содержит 40 карточек с заданиями, которые можно взять на прогулку в парк, лес, на речку. Карточки не дадут заскучать ни одному юному исследователю! Вас ждут опыты, игры, задания на внимательность, творческие мастер-классы.</p>
 <p>"Возми в лес" - это 40 готовых увлекательных занятий для детей на природе для детей дошкольного и младшего школьного возраста. Они подходят для сомостоятельных занятий, так и вместе с родителями. "Возми в лес" можно использовать для составления уроков по природоведению и экологии, квестов и конкурсов.</p>
 <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  <p>
  <span><strong>Цена: </strong></span>
  <span itemprop="price">800</span>
  <span> руб.</span>
  <meta itemprop="priceCurrency" content='RUB'/>
  </p>
  </div>

  <br></br>

 <BuyButton ItemId={1} />
 <GoToCartButton/> 
 <BackButton/>
 <br></br>

 <p><b>Познокомьтесь с другими играми этой категории:</b></p> 
 <p><a href="https://naturegames.ru/forest-cards-winter" className='greenlink'>Карточки с заданиями и играми для детей "Возьми в лес - Зима"</a></p>

 </div>
 

  </div>
</div>


<br></br>

<h3>Как заниматься с детьми вместе с игрой «Возьми в лес»</h3>
<h4>Карточки <strong>"Возми в лес"</strong> на прогулке по лесу или парку</h4>
<p>Прогулки на природе полезны для ребенка, так как они улучшают физическое здоровье, развивают когнитивные способности, улучшают эмоциональное состояние, способствуют обучению социальным навыкам и стимулируют творческое мышление. Также прогулки на природе являются отличным способом экологического образования для детей.</p>
<h4>Развитие воображения</h4>
<p>Игра <strong>"Возми в лес"</strong> содержит интересные идеи для мастер классов и увлекательных заданий. Изготовление поделок из природных материалов, таких как листья, ветки, камни, не только активно вовлекает ребенка в творческий процесс, но и развивает его воображение. В результате таких занятий дети учатся ценить красоту окружающего мира и развивать свои навыки наблюдения.</p>
<h4>Ответственное отношение к природе</h4>
<p>Занятия на природе стимулируют ответственное отношение к окружающей среде. Дети учатся понимать, как их действия влияют на природу, и осознают важность бережного отношения к ней. Такие мероприятия также развивают чувство гордости и любви к своему родному краю.</p>
<h4>Увлекательная игра</h4>
<p>Наши карточки <strong>«Возьми в лес»</strong> помогут организовать занятия на природе в виде увлекательной игры, они способствуют изучать окружающий мир и получить удовольствие от общения с ним. С их помощью можно провести квест или организовать соревнования на лучшую поделку. Такие игры не только развлекут детей, но и помогут им стать более внимательными и заботливыми к природе.</p>

      </div>
      </div>
    )
  }
}

export default ForestCardsSummer