import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import {Navbar1} from "./components/Navbar";
import {Navbar2} from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import {Home} from './pages/home/home';
import {About} from './pages/pages/about';
import {Payment} from './pages/pages/payment';
import {Cart} from './pages/cart/cart';
import {SortGame} from './online-games/sort-game';
import {SortMemoGame} from './online-games/sort-memo-game';
import {BirdsMemo} from './online-games/birds-memo';
import {ShopContextProvider} from "./context/shop-context";
import {CustomerInfo} from './pages/cart/customer-info';
import {Thanks} from './pages/cart/thanks';
import {SiteMap} from './pages/pages/SiteMap';
import {Page404} from './pages/pages/Page404';
import {Catalogue} from './pages/pages/Catalogue';
import {ForestCards} from './pages/pages/ForestCards';
import GamesOnline from "./pages/pages/GamesOnline";
import ForestCardsSummer from "./pages/Games/ForestCardsSummer";
import ForestCardsWinter from "./pages/Games/ForestCardsWinter";
import Ecosled from "./pages/Games/Ecosled";
import Zvezdy from "./pages/Games/ZvezdyNaLadoshke";
import Sortiruy from "./pages/Games/Sortiruy";
import EmptyBucket from "./pages/Games/EmptyBucket";
import BaikalPutanica from "./pages/Games/BaikalPutanica";
import  BaikalAzbuka from "./pages/Games/BaikalAzbuka";
import { Navbar } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import NotFound from "./NotFound";
import AboutSiteDeveloper from "./pages/pages/AboutSiteDeveloper";



class App extends React.Component {

  render() {
    
    // const helmet = Helmet.renderStatic();
   
    return ( 

      <div className="wrapper">
      <div>

      {/* ${helmet.title.toString()}
        ${helmet.description.toString()} */}
    
     <ShopContextProvider>
      <BrowserRouter>
        <div className="navbar2">
      <Navbar2/>
      <Navbar1/>
      </div>
      <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/cart" element={<Cart/>} />
            <Route path="/catalogue" element={<Catalogue/>} />
            <Route path="/sort-game" element={<SortGame/>} />
            <Route path="/sort-memo-game" element={<SortMemoGame/>} />
            <Route path="/birds-memo" element={<BirdsMemo/>} />
            <Route path="/customer-info" element={<CustomerInfo/>} />
            <Route path="/thanks" element={<Thanks/>} />
            <Route path="/payment" element={<Payment/>} />
            <Route path="/forest-cards-app" element={<ForestCards/>} />
            <Route path="/games-online" element={<GamesOnline/>} />
            <Route path="/forest-cards-summer" element={<ForestCardsSummer/>} />
            <Route path="/forest-cards-winter" element={<ForestCardsWinter/>} />
            <Route path="/ecosled" element={<Ecosled/>} />
            <Route path="/zvezdy-na-ladoshke" element={<Zvezdy/>} />
            <Route path="/sortiruy" element={<Sortiruy/>} />
            <Route path="/empty-bucket" element={<EmptyBucket/>} />
            <Route path="/baikal-putanica" element={<BaikalPutanica/>} />
            <Route path="/baikal-azbuka" element={<BaikalAzbuka/>} />
            <Route path="/site-map" element={<SiteMap/>} />
            <Route path="*" element={<Page404/>}/>
            <Route path="/developer" element={<AboutSiteDeveloper/>} />
        </Routes>
      </BrowserRouter>
     </ShopContextProvider>
      </div>            
       <div className="footer2"><Footer /></div>
        
      </div>
    );
  }


}


export default App;
